import GATSBY_COMPILED_MDX from "/opt/build/repo/content/projects/urban-fabric/index.mdx";
import React from 'react';
import {graphql} from 'gatsby';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MdxRender from 'components/mdx-render';
import Meta from 'components/meta';
import RedirectToParent from 'components/redirect-to-parent';
import Wrapper from 'components/wrapper';
function MdxProject({children, location, pageContext, data: {mdx}}) {
  const {frontmatter: fm} = mdx ?? ({});
  const {author, tagline, tags, title, year} = fm ?? ({});
  const {projectData} = pageContext ?? ({});
  if (!projectData) return React.createElement(RedirectToParent, {
    location: location
  });
  const description = title + (year ? ` (${year})` : '') + (tagline ? ` - ${tagline})` : '');
  const renderHeader = () => {
    return React.createElement("div", {
      className: "my-2"
    }, React.createElement(Row, {
      className: "pt-1 align-items-end border-bottom border-secondary"
    }, React.createElement(Col, {
      xs: "12",
      lg: "8",
      className: "fs-3 media-title text-center text-lg-start"
    }, title), React.createElement(Col, {
      xs: "12",
      lg: "4",
      className: "fs-4 mb-2 text-center text-lg-end"
    }, (tags ?? []).map((tag, index) => React.createElement(Badge, {
      key: index,
      bg: "dark",
      className: "media-title p-1 me-2"
    }, tag)), year && React.createElement(Badge, {
      bg: "dark",
      className: "media-title p-1"
    }, year))));
  };
  return React.createElement(Wrapper, {
    location: location,
    variant: "project",
    wrapperClass: "d-flex flex-column w-100 mx-auto"
  }, React.createElement(Meta, {
    title: title,
    author: author,
    description: description
  }), renderHeader(), React.createElement(MdxRender, null, children));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxProject, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        tagline
        tags
        title
        year
      }
    }
  }
`;
