/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components), {Row, Col, Card, ContentImage, Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Col) _missingMdxReference("Col", true);
  if (!ContentImage) _missingMdxReference("ContentImage", true);
  if (!Row) _missingMdxReference("Row", true);
  return React.createElement(React.Fragment, null, React.createElement(Row, {
    className: "m-2 p-1 pt-0 g-2 bg-body-darker mdx-content"
  }, React.createElement(Col, {
    md: 4
  }, React.createElement(Card, {
    className: "border-0 text-center"
  }, React.createElement(ContentImage, {
    imagePath: "urban-fabric-prague/uf-prague-dvd-0658_16x9.jpg",
    alt: "Urban Fabric: Prague"
  }), React.createElement(Row, {
    className: "m-1 g-1 justify-content-center text-nowrap"
  }, React.createElement(Col, {
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: "/urban-fabric-prague"
  }, React.createElement(_components.p, null, "Urban Fabric: Prague")))))), React.createElement(Col, {
    md: 4
  }, React.createElement(Card, {
    className: "border-0 text-center"
  }, React.createElement(ContentImage, {
    imagePath: "urban-fabric-paris/uf-paris-dvd-0604_16x9.jpg",
    alt: "Urban Fabric: Paris"
  }), React.createElement(Row, {
    className: "m-1 g-1 justify-content-center text-nowrap"
  }, React.createElement(Col, {
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: "/urban-fabric-paris"
  }, React.createElement(_components.p, null, "Urban Fabric: Paris")))))), React.createElement(Col, {
    md: 4
  }, React.createElement(Card, {
    className: "border-0 text-center"
  }, React.createElement(ContentImage, {
    imagePath: "urban-fabric-beijing/uf-beijing-dvd-0932_16x9.jpg",
    alt: "Urban Fabric: Beijing"
  }), React.createElement(Row, {
    className: "m-1 g-1 justify-content-center text-nowrap"
  }, React.createElement(Col, {
    className: "flex-grow-0"
  }, React.createElement(Button, {
    className: "fs-6",
    variant: "primary",
    size: "sm",
    href: "/urban-fabric-beijing"
  }, React.createElement(_components.p, null, "Urban Fabric: Beijing"))))))), "\n", React.createElement(Row, {
    className: "py-3 justify-content-center text-center"
  }, React.createElement(Col, {
    md: 10
  }, React.createElement("div", {
    className: "lead"
  }, React.createElement(_components.p, null, "A series of interactive dance/media works staged in ", React.createElement(_components.a, {
    href: "/urban-fabric-prague"
  }, "Prague"), ",\n", React.createElement(_components.a, {
    href: "/urban-fabric-paris"
  }, "Paris"), " and ", React.createElement(_components.a, {
    href: "/urban-fabric-beijing"
  }, "Beijing"), " where performers encounter\nprocessed landscapes of media fragments (images, texts, sounds, movement, memories) sampled from\ncities real and imaginary.")), React.createElement(_components.p, null, "Influenced and inspired by the ", React.createElement(_components.em, null, "Invisible Cities"), " of Italo Calvino, the performances in the ", React.createElement(_components.em, null, "Urban Fabric"), " project reflect on the urban experience as an embodiment of collective memory, a repository for narrative that continually evolves through our physical responses to the circumstances we inhabit. The project responds to the emerging notion of “performative architecture,” an approach to architectural production emphasizing dialogues between architectural space, motion and time. Performative investigation of relationships between architecture and movement can help reveal underlying structures that connect human experience with the spaces we inhabit."), React.createElement(_components.p, null, "In ", React.createElement(_components.em, null, "Urban Fabric"), ", dancers, musicians and media artists are accompanied on stage by projected visuals. Using the ", React.createElement(_components.a, {
    href: "https://as.embodied.net"
  }, "Active Space"), " intermedia performance system, we combine live onstage camera feeds with footage shot on location and an architectural rendering of Calvino text. The movement of the dancers directly influences the video and audio accompaniment generated by the system. A video-based motion tracking component recognizes various characteristics of the dance performance. The results of this analysis influence the behavior of two real-time interactive visual composition systems; one for visuals and one for sound. Responding to the movement of the dancers, the composition systems produce synthesized video and audio, combined with non-linear playback of clips and samples."), React.createElement(_components.p, null, "While the performance by the dancers is the center of the piece, the media artists are performers as well. However, they do not explicitly \"play\" or manipulate instruments or devices to produce visuals and sound. Instead, they watch the dance and continually monitor the interactive behaviors of the computer-based music and video composition systems, listening and observing how software responds to the movement of the dancers. As the media systems play, the media artists make adjustments to parameters and algorithms, tuning the media behaviors, shaping the responsiveness to the circumstances of the dance."), React.createElement(_components.p, null, "As dancers and media artists interact with the performance system, its responses and behaviors become construed as mechanisms for communication. The media system itself becomes a message, or a series of messages, an embodied sequence of codes, exchanged among performers and between performers and audience."))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
